<template>
  <div>
    <div class="register-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-5 p-0">
            <div class="form-wrapper left-panel position-relative">
              <div class="logo">
                <router-link
                  :to="{ name: 'anonymousRoute-home' }"
                  class="logo-image"
                >
                  <img
                    src="/img/digital-staging-logo.svg"
                    alt="Digital Staging"
                  />
                </router-link>
              </div>
              <div
                class="register-banner-mobile d-xs-block d-sm-block d-md-block d-lg-none"
              >
                <img src="/img/login-banner-mobile.png" alt="Sign up Banner" />
              </div>
              <div class="reset-password-wrapper">
                <div class="register position-relative">
                  <h2 class="blue-text font-weight-bold">
                    パスワードはを設定する
                  </h2>
                  <form v-on:submit="createAccount">
                    <div class="mt-4">
                      <p class="m-0 font-weight-bold">電子メールアドレス</p>
                      <h5 class="light-grey-text">{{ email }}</h5>
                    </div>
                    <div class="mt-4">
                      <p class="m-0 font-weight-bold">パスワードは</p>
                      <div class="d-flex">
                        <input
                          id="new-password"
                          class="form-control d-inline shadow-1"
                          type="password"
                          v-model="newPassword"
                          v-on:keyup="hintChecker"
                          minlength="2"
                          maxlength="100"
                          placeholder="パスワードはを入力してください"
                          style="
                            border-top-right-radius: 0 !important;
                            border-bottom-right-radius: 0 !important;
                            border-right: 0;
                          "
                          required
                        />
                        <b-tooltip
                          target="new-password"
                          placement="bottom"
                          custom-class="passwordTooltip"
                          variant="danger"
                          :show.sync="passwordTooltip"
                          :disabled.sync="disabledPasswordTooltip"
                        >
                          <div>
                            <p v-if="!rules.length">
                              パスワードは8文字以上必要です。
                            </p>
                            <p v-if="!rules.uppercase">
                              パスワードは1文字以上大文字が必要です。
                            </p>
                            <p v-if="!rules.lowercase">
                              パスワードは1文字以上小文字が必要です。
                            </p>
                          </div>
                        </b-tooltip>
                        <button
                          class="btn btn-ds transparent m-0 d-inline shadow-1"
                          type="button"
                          style="
                            height: 56px;
                            width: 56px;
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            border-top: 1px solid #ced4da;
                            border-bottom: 1px solid #ced4da;
                            border-right: 1px solid #ced4da;
                          "
                          v-on:click="showPasswordToggle('new-password')"
                          v-waves.light
                        >
                          <font-awesome-icon
                            :icon="{
                              prefix: 'fas',
                              iconName: newPasswordIcon,
                            }"
                          />
                        </button>
                      </div>
                    </div>
                    <div class="mt-4">
                      <p class="m-0 font-weight-bold">パスワード（確認用）</p>
                      <div class="d-flex">
                        <input
                          id="confirm-password"
                          class="form-control d-inline shadow-1"
                          type="password"
                          v-model="confirmPassword"
                          v-on:keyup="formMessage = null"
                          minlength="2"
                          maxlength="100"
                          placeholder="パスワードを入力して下さい"
                          style="
                            border-top-right-radius: 0 !important;
                            border-bottom-right-radius: 0 !important;
                            border-right: 0;
                          "
                          required
                        />
                        <button
                          class="btn btn-ds transparent m-0 d-inline shadow-1"
                          type="button"
                          style="
                            height: 56px;
                            width: 56px;
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            border-top: 1px solid #ced4da;
                            border-bottom: 1px solid #ced4da;
                            border-right: 1px solid #ced4da;
                          "
                          v-on:click="showPasswordToggle('confirm-password')"
                          v-waves.light
                        >
                          <font-awesome-icon
                            :icon="{
                              prefix: 'fas',
                              iconName: confirmPasswordIcon,
                            }"
                          />
                        </button>
                      </div>
                    </div>
                    <b-alert
                      class="m-0 mt-3 light-grey-outline grey-text"
                      variant="warning"
                      show
                      v-if="formMessage !== null"
                    >
                      {{ formMessage }}
                    </b-alert>
                    <button
                      class="btn btn-ds mt-4 dark-blue white-text"
                      type="submit"
                      v-waves.light
                    >
                      OK
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-7 p-0 d-none d-lg-block banner-wrap">
            <img src="/img/login-banner.png" alt="Sign up Banner" />
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
// Import Services
import {
  globalService_EmailPasswordResetLinkTokenChecker,
  globalService_EmailPasswordResetLinkUpdatePassword,
} from '../services/global';

// Important Custom Components
import mainFooter from '../components/customer/Main-Footer.vue';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Forgot Password',
    };
  },

  components: {
    'main-footer': mainFooter,
  },

  data() {
    return {
      email: null,
      newPassword: null,
      confirmPassword: null,
      newPasswordIcon: 'eye-slash',
      confirmPasswordIcon: 'eye-slash',
      rules: {
        length: false,
        uppercase: false,
        lowercase: false,
      },
      token: null,
      passwordTooltip: false,
      disabledPasswordTooltip: false,
      formMessage: null,
    };
  },
  watch: {
    rules: {
      handler(newVal, oldVal) {
        if (Object.values(newVal).includes(false)) {
          this.passwordTooltip = true;
          this.disabledPasswordTooltip = false;
        } else {
          this.disabledPasswordTooltip = true;
          this.passwordTooltip = false;
        }
      },
      deep: true,
    },
  },

  mounted() {
    // Disable on paste on confirm password
    let confirmPassword = document.querySelector('#confirm-password');
    confirmPassword.onpaste = (e) => e.preventDefault();

    this.validateUser();
  },

  methods: {
    validateUser() {
      let _this = this;

      let url = new URL(location.href);
      let email = url.searchParams.get('email');
      let token = url.searchParams.get('token');

      if (
        email !== null &&
        email.length > 0 &&
        token !== null &&
        token.length > 0
      ) {
        globalService_EmailPasswordResetLinkTokenChecker({
          token: token,
        })
          .then((response) => {
            console.log(response.data);

            _this.email = email;
            _this.token = token;
          })
          .catch((error) => {
            console.log(error);

            _this.$router.push({
              name: 'errorRoute-401',
            });
          });
      } else {
        _this.$router.push({
          name: 'errorRoute-401',
        });
      }
    },

    showPasswordToggle(element) {
      let _element = document.querySelector(`#${element}`);

      if (_element.getAttribute('type') === 'password') {
        _element.setAttribute('type', 'text');

        if (element === 'new-password') {
          this.passwordIcon = 'eye';
        }

        if (element === 'confirm-password') {
          this.confirmPasswordIcon = 'eye';
        }
      } else {
        _element.setAttribute('type', 'password');

        if (element === 'password') {
          this.passwordIcon = 'eye-slash';
        }

        if (element === 'confirm-password') {
          this.confirmPasswordIcon = 'eye-slash';
        }
      }
    },

    hintChecker() {
      this.formMessage = null;

      if (this.newPassword !== null && this.newPassword.length > 0) {
        if (this.newPassword.length >= 8) {
          this.rules.length = true;
        } else {
          this.rules.length = false;
        }

        if (/[A-Z]/.test(this.newPassword)) {
          this.rules.uppercase = true;
        } else {
          this.rules.uppercase = false;
        }

        if (/[a-z]/.test(this.newPassword)) {
          this.rules.lowercase = true;
        } else {
          this.rules.lowercase = false;
        }
      } else {
        this.rules.length = false;
        this.rules.uppercase = false;
        this.rules.lowercase = false;
      }
    },

    createAccount(e) {
      let _this = this;

      e.preventDefault();

      _this.formMessage = null;

      _this.$store.state.modalLoaderMessage = 'リセット中';
      _this.$bvModal.show('modal-loader');

      if (_this.newPassword === _this.confirmPassword) {
        if (_this.newPassword.length > 7) {
          if (/[A-Z]/.test(_this.newPassword)) {
            if (/[a-z]/.test(_this.newPassword)) {
              globalService_EmailPasswordResetLinkUpdatePassword({
                password: _this.newPassword,
                password_confirmation: _this.confirmPassword,
                token: _this.token,
              })
                .then((response) => {
                  console.log(response.data);

                  _this.newPassword = null;
                  _this.confirmPassword = null;

                  _this.$router.push({
                    name: 'anonymousRoute-reset-password-update-success',
                  });

                  setTimeout(() => {
                    _this.$bvModal.hide('modal-loader');
                  }, 500);
                })
                .catch((error) => {
                  console.log(error);

                  _this.formMessage =
                    'エラー起こりました。再度お試しください。';

                  setTimeout(() => {
                    _this.$bvModal.hide('modal-loader');
                  }, 500);
                });
            } else {
              setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
              }, 500);

              _this.formMessage =
                'パスワードは1文字以上小文字が必要です。';
            }
          } else {
            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);

            _this.formMessage =
              'パスワードは1文字以上大文字が必要です。';
          }
        } else {
          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);

          _this.formMessage = 'パスワードは8文字以上必要です。';
        }
      } else {
        setTimeout(() => {
          _this.$bvModal.hide('modal-loader');
        }, 500);

        _this.formMessage =
          'パスワード確認が一致しません。';
      }
    },
  },
};
</script>

<style scoped>
.hints ul {
  list-style: none;
  padding: 0;
  font-size: 12px;
}

.hint-enter {
  opacity: 0;
  transform: translate3d(-20px, 0, 0);
}

.hint-leave-to {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}

.hint-enter-active {
  transition: 300ms;
}

.hint-leave-active {
  transition: 400ms;
}

.register-wrapper .form-wrapper {
  padding: 42px;
  height: 100vh;
}

@media (max-width: 991.98px) {
  .register-wrapper .form-wrapper {
    height: auto;
  }
}

.register-wrapper .form-wrapper .lets-start {
  background-color: #00adee;
  color: #fff;
  border-radius: 25px;
  position: absolute;
  right: -178px;
  padding: 15px 12px;
  width: 175px;
  text-align: center;
  height: 53px;
  margin: 5px 0;
}

.register-wrapper .form-wrapper .reset-password-wrapper {
  padding: 40px 40px 10px 140px;
}

.register-wrapper .form-wrapper .reset-password-wrapper .title-form {
  font-weight: 500;
}

.register-wrapper .form-wrapper .reset-password-wrapper form .form-group {
  position: relative;
}

.register-wrapper .form-wrapper .reset-password-wrapper form .btn-google {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bfbebe;
  border-radius: 100px;
  color: #707070;
}

.register-wrapper .form-wrapper .reset-password-wrapper form .option-links p a,
.register-wrapper .form-wrapper .reset-password-wrapper form .option-links a {
  color: #034ea1;
  text-decoration: underline !important;
}

.register-wrapper .form-wrapper .form-footer {
  padding: 10px 40px 10px 140px;
  position: absolute;
  margin: 1em auto;
  overflow: hidden;
  bottom: 0;
  width: 80%;
  margin: auto;
  left: 0;
  bottom: 0;
  right: 0;
}

.register-wrapper .form-wrapper .form-footer ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -1px;
  list-style: none;
  padding-left: 0;
}

.register-wrapper .form-wrapper .form-footer ul li {
  flex-grow: 1;
  flex-basis: auto;
  margin: 0.25em 0;
  padding: 0 1em;
  text-align: center;
  border-left: 1px solid #ccc;
}

.register-wrapper .form-wrapper .form-footer ul li:first-child {
  border-left: 0 !important;
}

.register-wrapper .form-wrapper .form-footer ul li a {
  text-decoration: underline !important;
  color: #9b9b9b;
}

.register-wrapper .banner-wrap {
  height: 100vh;
  overflow: hidden;
  padding: 0;
}

.register-wrapper .banner-wrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: left center;
  object-position: left center;
}

@media (max-width: 991.98px) {
  .register-wrapper .form-wrapper {
    padding: 0 !important;
  }

  .register-wrapper .form-wrapper .logo {
    padding: 20px;
  }

  .register-wrapper .form-wrapper .logo .logo-image img {
    width: 172px;
  }

  .register-wrapper .form-wrapper .logo .lets-start {
    right: 20px !important;
    padding: 8px 10px;
    height: auto;
    margin: 0;
  }

  .register-wrapper .form-wrapper .reset-password-wrapper {
    padding: 20px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    position: relative;
    top: -35px;
    background: #fff;
  }

  .register-wrapper .form-wrapper .form-footer {
    padding: 20px;
    position: relative !important;
    width: 100%;
  }

  .register-wrapper .form-wrapper .register-banner-mobile img {
    width: 100%;
    height: 251px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .register-wrapper .form-wrapper {
    padding: 10px 10px 10px 42px !important;
  }
  .register-wrapper .form-wrapper .reset-password-wrapper,
  .register-wrapper .form-wrapper .form-footer {
    padding: 40px 10px 10px 0 !important;
  }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
  .register-wrapper .form-wrapper {
    padding: 10px 10px 10px 42px !important;
  }
  .register-wrapper .form-wrapper .reset-password-wrapper,
  .register-wrapper .form-wrapper .form-footer {
    padding: 40px 10px 10px 40px !important;
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 0.5px solid #b8b8b8;
}

.separator:not(:empty)::before {
  margin-right: 8px;
}

.separator:not(:empty)::after {
  margin-left: 8px;
}
</style>
